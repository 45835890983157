<template>
  <v-container class="help">
    <h2 class="text-sm-h3 text-h4 mb-4">Guides and support</h2>
    <p>Welcome to the NFDI4ING Data Ingest Service for civil engineering, architecture and urbanism! This page provides guidance on how to use the service, what to expect, and how to troubleshoot any issues you may encounter.</p>
    <v-divider class="mt-6"></v-divider>
    <h3 class="text-sm-h4 text-h5 my-4">Searching for Data</h3>
    <p>Currently, our search functionality is very basic, we plan to develop it further in the near future to support search requests based on application profiles. Nevertheless, users can currently search all uploaded research data, as well as a growing collection of externally collected and curated data.</p>
    <v-divider class="mt-6"></v-divider>
    <h3 class="text-sm-h4 text-h5 my-4">Uploading of Data</h3>
    <p>To upload your research data, you first need to click on the “Upload” button and authenticate via <a href="https://www.dfn.de">DFN</a> or <a href="https://orcid.org">ORCID</a>. Once authenticated, you can follow these steps:</p>
    <ol class="mb-4">
      <li>
        <b>Type</b>: Select the type of data you want to publish. The "General" template corresponds to classic repositories, all others represent subject-specific metadata schemas.
      </li>
      <li>
        <b>Files</b>: Select the data files you want to upload. This might automatically launch a set of processes to prepare and process your data, which you can follow in the status bar at the bottom.
      </li>
      <li>
        <b>Metadata</b>:
        Specify all metadata information. Where possible, metadata is automatically read from the files and prefilled.
      </li>
      <li>
        <b>Publish</b>:
        Submit your dataset.
      </li>
    </ol>
    <p>If you encounter an error while uploading your data, please try again, making sure that you have met all the requirements. If the problem persists, please contact us.</p>
    <v-divider class="mt-6"></v-divider>
    <h3 class="text-sm-h4 text-h5 my-4">Preview files</h3>
    <p>Our service automatically generates previews for large 2D and 3D files. This process may take some time, depending on the size and complexity of the files. It runs independently of your computer, thus – once the files are uploaded – you can close the window and return at a later time. If you have any questions or concerns regarding the previews or metadata, please reach out to us.</p>
    <v-divider class="mt-6"></v-divider>
    <h3 class="text-sm-h4 text-h5 my-4">Metadata</h3>
    <p>The NFDI4ING Data Ingest Service allows users to attach metadata on a domain-specific level using application profiles based on <a href="https://www.w3.org/TR/rdf-concepts/">RDF</a> <a href="http://www.w3.org/TR/shacl/">(SHACL)</a> and domain-specific vocabularies. It will be built in conformity with the developments of the <a href="https://nfdi4ing.de/">National Research Data Infrastructure for Engineering (NFDI4Ing)</a>.</p>
    <v-divider class="mt-6"></v-divider>
    <h3 class="text-sm-h4 text-h5 my-4">Types of data and files</h3>
    <p>In general, we accept all research data from the fields of civil engineering, architecture and Urbanism. Current services for processing are mainly optimized for 2D- and 3D-Data, further developments are coming soon. The full list of currently processed file formats can be found in our <a href="https://ingest.nfdi4ing.de/api/#/Data%20homogenization%20and%20acquisition/ConvertFile">REST-API Documentation</a>. If you have questions about a specific file format or want to propose a feature for specific file-types, please contact us.</p>
    <v-divider class="mt-6"></v-divider>
    <h3 class="text-sm-h4 text-h5 my-4">Application Programming Interface (API)</h3>
    <p>We provide a REST-API that allows users to interact with the NFDI4ING Data Ingest Service programmatically. Using the API, you can perform operations such as detecting file formats or converting data into the supported target formats. You can find the API documentation at <a href="https://ingest.nfdi4ing.de/api/">https://ingest.nfdi4ing.de/api/</a>. Please note that these operations require authentication via DFN or ORCID.</p>
    <p>We also provide a SPARQL endpoint at <span class="text-decoration-underline">https://ingest.nfdi4ing.de/api/v1/sparql</span> to flexibly query metadata of published datasets using any SPARQL compliant client software.</p>
    <v-divider class="mt-6"></v-divider>
    <h3 class="text-sm-h4 text-h5 my-4">Contact Information</h3>
    <p>If you need further assistance, have any questions or feedback, please contact us at <a href="mailto:ingest@nfdi4ing.de" class="text-none pa-0">ingest@nfdi4ing.de</a>.</p>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'HelpPage'
})
</script>
<style>
.help { font-size: 24px; font-weight: 350; }
</style>
